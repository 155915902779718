<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref, computed, h } from 'vue'
import { NCountdown, NImage, useMessage, NButton, CountdownInst } from 'naive-ui'
import { fetchGetQRCodeAPI, fetchGetQRSceneStrAPI, fetchLoginBySceneStrAPI, fetchWxLoginSaveInfoAPI } from '@/api/user'
import type { ResData } from '@/api/types'
import { useRouter } from 'vue-router'
import { useAuthStore, useGlobalStore } from '@/store'
import { ss } from '@/utils/storage'
import Motion from '@/utils/motion/index'
let timer: any
import wechatIcon from '@/assets/wechat.png'
import Send from './send.vue'
const router = useRouter()
interface Emit {
  (ev: 'changeLoginType', val: string): void
}
const emit = defineEmits<Emit>()
const GlobalStore = useGlobalStore()
const wxLoginUrl = ref('')
const sceneStr = ref('')
const activeCount = ref(false)
const Nmessage = useMessage()
const authStore = useAuthStore()
const countdownRef = ref<CountdownInst | null>()
const phoneLoginStatus = computed(() => Number(authStore.globalConfig.phoneLoginStatus) === 1)
const emailLoginStatus = computed(() => Number(authStore.globalConfig.emailLoginStatus) === 1)
const userAgreement = computed(() => authStore.globalConfig.userAgreement)
const userPrivacy = computed(() => authStore.globalConfig.userPrivacy)


const registerForm = ref({
  consentTimestamp: '',
  userAgreementVersion: '',
  privacyPolicyVersion: ''
})

function loadImage(src: string) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => resolve(img)
    img.onerror = reject
    img.src = src
  })
}

async function getSeneStr() {
  const params = { invitedBy: ss.get('invitedBy') }
  const res: ResData = await fetchGetQRSceneStrAPI(params)
  if (res.success) {
    sceneStr.value = res.data
    getQrCodeUrl()
  }
}

async function loginBySnece() {
  if (!sceneStr.value)
    return

  const res: ResData = await fetchLoginBySceneStrAPI({ sceneStr: sceneStr.value })
  if (res.data) {
    clearInterval(timer)
    Nmessage.success('账户登录成功、开始体验吧！')
    authStore.setToken(res.data)
    await authStore.getUserInfo()
    authStore.setLoginDialog(false)
    ss.remove('invitedBy')
    router.push({ path: '/chat' })
    GlobalStore.updateLoginCount()
    verifyProtocol()
  }
}

async function getQrCodeUrl() {
  const res: ResData = await fetchGetQRCodeAPI({ sceneStr: sceneStr.value })
  if (res.success) {
    activeCount.value = true
    await loadImage(res.data)
    wxLoginUrl.value = res.data
    timer = setInterval(() => {
      loginBySnece()
    }, 1000)
  }
}

function handleTimeDown() {
  clearInterval(timer)
  getSeneStr()
  countdownRef.value?.reset()
}

function verifyProtocol() {
  const consentState = computed(() => authStore.userInfo.consentState)
  if (!consentState.value) {
    initFormValue()
    verifyInfo()
  }
}

function initFormValue() {
  let Math1 = userAgreement.value.split('/').pop();
  let Math2 = userPrivacy.value.split('/').pop();
  let userAgreementMatch = Math1.split('.').shift();
  let userPrivacyMatch = Math2.split('.').shift();
  registerForm.value.privacyPolicyVersion = userPrivacyMatch
  registerForm.value.userAgreementVersion = userAgreementMatch
  registerForm.value.consentTimestamp = new Date().toISOString()
}

async function verifyInfo() {
  const params: any = registerForm.value
  await fetchWxLoginSaveInfoAPI(params)
}

onMounted(() => {
  getSeneStr()
})

onBeforeUnmount(() => {
  clearInterval(timer)
})
</script>

<template>
  <div class="w-full h-full flex flex-col items-center">
    <div class="text-[#374151] dark:text-white font-bold text-[20px] mt-[50px]">微信扫码登录</div>
    <div style="white-space: nowrap" class="mt-[20px] w-full text-center font-bold text-sm">
      <p>
        <span class="w-[65px] inline-block font-normal text-[#FF505C] text-left">
          <NCountdown ref="countdownRef" :active="activeCount" :duration="60 * 1000" :on-finish="handleTimeDown" />
        </span> 秒后二维码将刷新
      </p>
    </div>

    <Motion :delay="200" :scale="0.5" :duration="500">
      <div class="w-[280px] h-[280px] flex flex-col justify-center items-center relative select-none mt-[20px]">
        <NImage v-if="wxLoginUrl" preview-disabled class="w-[220px] h-[220px] select-none" :src="wxLoginUrl" />
        <!-- <NSkeleton v-else height="230px" width="228px" animated /> -->
        <div class="mt-2 text-[#222222] dark:text-white font-normal flex items-center">
          <img :src="wechatIcon" class="w-[16px]  mr-1" alt="">
          微信扫码
        </div>
      </div>
    </Motion>
    <div class="flex items-center justify-center space-x-2 mt-[10px]">
      <n-button type="default" v-if="emailLoginStatus" ghost class="!px-5 !rounded-xl !font-semibold loginBtn"
        @click="emit('changeLoginType', 'email')">
        邮箱号登录
      </n-button>
      <n-button type="default" v-if="phoneLoginStatus" ghost class="!px-5 !rounded-xl !font-semibold loginBtn"
        @click="emit('changeLoginType', 'phone')">
        手机号登录
      </n-button>
    </div>
    <Send />
    <div class="mt-2">
      <span class="ml-2 text-xs text-[#a2a3a5]">登录视为您已同意
        <span class="hover:text-[#055aff]" >
          <span>
            <a :href="userAgreement" target="_blank" rel="noopener noreferrer">用户协议</a>
          </span>、
          <span>
            <a :href="userPrivacy" target="_blank" rel="noopener noreferrer">隐私政策</a>
          </span>
        </span>
      </span>
    </div>
  </div>
</template>

<style scoped lang="less">
.loginBtn {
  &:hover {
    background-color: #eceded !important;
    color: #000000 !important;
  }
}

::v-deep .n-button {
  --n-border-hover: 1px solid transparent !important;
  --n-border-pressed: 1px solid transparent !important;
}

.n-button:not(.n-button--disabled):active {
  color: #000000;
}

.codeMask::before {
  width: 100%;
  height: 100%;
  content: "";
  background-color: rgba(255, 255, 255, 0.7);
  /* 半透明白色遮罩 */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(4px);
  /* 模糊效果 */
  color: #333;
  /* 文本颜色 */
  font-size: 16px;
  text-align: center;
  z-index: 1;
  border-radius: 10px;
  /* 设置遮罩圆角 */
}
</style>
